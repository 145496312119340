
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  IAccountingInAYearItem,
  IAccountingInAYear,
  IAmount,
  IAccountingInAYearItemDocumentViewer,
  IMovement,
  IDocumentViewer,
} from "@/models/IPropertyAccounting";
import { HousfyButton, HousfyAnchor } from "housfy-ui-lib";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import Money from "@/models/Money";
import { LocaleMessages } from "vue-i18n";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import InvoicesFlap from "@/components/InvoicesFlap.vue";
import { ErpJournalEntry } from "@/constants/ErpJournalEntry";
import { ErpInvoiceType } from "@/constants/ErpInvoiceType";
import { AccountingSummary } from "@/enums/Accounting";
import Storage from "@/plugins/storage";
import { LocalStorageKeys } from "@/enums/Storage";
import { resolveRoutePath } from "@/router";

@Component({
  components: {
    HousfySvg,
    HousfyButton,
    HousfyAnchor,
    InvoicesFlap,
  },
})
export default class AccountingMonthlyCardsList extends Vue {
  @Prop({ type: Object, required: true })
  accountingInAYear!: IAccountingInAYear;

  @Prop({ type: Number, default: AccountingSummary.TOTAL_PROPERTY })
  summary!: number;

  showAllMovements = false;
  initialMonthsToShow = 2;
  selectedItem: IAccountingInAYearItemDocumentViewer | null = null;

  get accountingInAYearItemsSortedByMonth(): IAccountingInAYearItem[] {
    return (
      this.summaryView.months.sort((a, b) => {
        const dateA = new Date(a.factMonth);
        const dateB = new Date(b.factMonth);
        return dateB.getTime() - dateA.getTime();
      }) || []
    );
  }

  get summaryView() {
    if (this.summary === AccountingSummary.OWNER) {
      return this.accountingInAYear?.ownerSummary;
    }
    return this.accountingInAYear?.totalPropertySummary;
  }

  hasDocumentsUrls(item: any): boolean {
    return (
      item.movements &&
      item.movements.some((movement: any) => movement.downloadUrl !== null)
    );
  }

  handleInvoicesButtonClick(item: IAccountingInAYearItem): void {
    const documentsUrls = item.movements.filter(
      (movement) => movement.downloadUrl !== null
    );
    if (!documentsUrls.length) return;
    if (documentsUrls.length === 1) {
      this.openFile(documentsUrls[0]);
      return;
    }

    this.selectedItem = {
      ...item,
      invoices: documentsUrls.map(
        (movement) =>
          ({
            title: this.getMovementConceptTranslation(
              movement.concept,
              movement.amountMinUnit.amount,
              this.getMonthNameString(movement.date, "short")
            ),
            links: [
              { downloadUrl: movement.downloadUrl, viewUrl: movement.viewUrl },
            ],
          } as IDocumentViewer)
      ),
    } as IAccountingInAYearItemDocumentViewer;
  }

  unselectItem(): void {
    this.selectedItem = null;
  }

  openFile(fileUrl: IMovement): void {
    const file = {
      title:
        fileUrl.concept === "F36-outcome"
          ? this.$t("common.monthlyRentFeesAndExpenses")
          : this.getMovementConceptTranslation(
              fileUrl.concept,
              fileUrl.amountMinUnit.amount,
              this.getMonthNameString(fileUrl.date, "short")
            ),
      links: [{ downloadUrl: fileUrl.downloadUrl, viewUrl: fileUrl.viewUrl }],
    };
    Storage.setLocalStorageItem(LocalStorageKeys.IFRAME_URL, file);
    const route = resolveRoutePath({
      name: "documentViewer",
    });
    this.$router.push(route);
  }

  getMonthName(month: number, type: "short" | "long"): string {
    const date = new Date();
    date.setMonth(month - 1);
    const options = { month: type };

    return date.toLocaleString(MyRentalsConfig.country(), options);
  }

  getMonthNameString(month: string, type: "short" | "long"): string {
    const date = new Date(month);
    const options = { month: type };

    return date.toLocaleString(MyRentalsConfig.country(), options);
  }

  getYear(month: string): number {
    const date = new Date(month);
    return date.getFullYear();
  }

  getMovementConceptTranslation(
    concept: string,
    income: number,
    month: string
  ): LocaleMessages | string {
    const formatMonth = new Date(month).getMonth() + 1;

    if (concept === "income")
      return `${this.$t("common.rent")} ${this.getMonthName(
        formatMonth,
        "long"
      )}`;

    if (concept === "LIQ") return `${this.$t("common.liquidation")}`;

    const letter = concept.charAt(0).toLowerCase();
    const number = concept.slice(1);

    switch (letter) {
      case "e": {
        const conceptKeyName: keyof typeof ErpJournalEntry =
          number as any as keyof typeof ErpJournalEntry;
        const conceptValue = ErpJournalEntry[conceptKeyName];

        if (!conceptValue) {
          if (income > 0) {
            return "Factura";
          } else {
            return "Abono";
          }
        }

        return this.$t(conceptValue)
          ? this.$t(conceptValue)
          : "Concepto no encontrado";
      }
      case "f": {
        const conceptKeyName: keyof typeof ErpInvoiceType =
          number as keyof typeof ErpInvoiceType;
        const conceptValue = ErpInvoiceType[conceptKeyName];

        if (!conceptValue) {
          if (income > 0) {
            return "Factura";
          } else {
            return "Abono";
          }
        }

        return this.$t(conceptValue)
          ? this.$t(conceptValue)
          : "Concepto no encontrado";
      }
      default:
        return "Concepto no encontrado";
    }
  }

  formatAmount({ amount, currency }: IAmount, withSign = false): string {
    if (isNaN(amount)) return "";
    const amountString = new Money(amount / 100)
      .withOptions({
        withCents: true,
        currency,
      })
      .format(false);
    return amount > 0 && withSign ? `+${amountString}` : amountString;
  }

  getStringDependingOnDate(date: string): string {
    const givenDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (givenDate.toDateString() === today.toDateString())
      return "common.today";
    else if (givenDate.toDateString() === yesterday.toDateString())
      return "common.yesterday";

    return `${givenDate.getDate()} ${givenDate.toLocaleString(
      MyRentalsConfig.country(),
      {
        month: "short",
      }
    )}`;
  }
}
